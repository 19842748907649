@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Federant&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gorditas&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap);
@import url(https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB);
@import url(https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT);
@import url(https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont);
* {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  font-family: "prompt", sans-serif;
}

* {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  line-height: 20px;
  font-family: "prompt", sans-serif;
}

@media only screen and (max-width: 600px) {
  /* select{
      background-color: white !important;
  } */
  input {
    background-color: white !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
.PageWrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 135vh; */
}

.AppWrapper {
  flex-grow: 1;
}

.FooterWrapper {
  flex-shrink: 0;
  z-index: 1;
}



/* html,
body {
  margin: 0;
  padding: 0;
} */
.dashboard-activateTag-popup {
  font-family: "prompt", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.dashboard-input__field__dialogs {
  padding: 0.375em 0.75em;
  border: 1px solid black;
  border-radius: 15px;
}
.dashboard-verifyOtp-button {
  font-family: "prompt", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #ffc107 !important;
  letter-spacing: 1px !important;
  padding: 8px 16px !important;
  border: none !important;
  outline: none !important;
  border-radius: 15px !important;
  color: white !important;
  box-shadow: 0px 2px 5px 0px #888888 !important;
}

.dashboard-verifyOtp-button:hover {
  background-color: #ebb105 !important;
}
.left-image {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 1rem;
  margin-top: 0rem;
  width: 50%;
}
.bagActivation-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.bag-container {
  background-color: #c7c7c781;
  width: 100%;
  max-width: 100%;
  height: 135vh; /* Default height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh 0 0 8vw;
}


.background-image {
  background: whitesmoke;
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
}

.bagActivation-left-wrapper {
  position: relative;
  background-color: white;
}
.bag-activation-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 5em;
  gap: 5em;
}
.bagActivation-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.bagActivation-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.bagActivation-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.bagActivation-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.bagActivation-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.bagActivation-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bagActivation-sidebar-menu-ul-hide {
  display: none;
}

.bagActivation-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.bag-activation-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  padding: 1em;
}

.bagactivation-label-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.bagactivation-label-petdata {
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.bag-activation-container > p {
  font-family: "prompt", sans-serif;
  text-align: center;
}

.bagactivation-input-tag {
  margin-top: 0 !important;
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.bagactivation-drop-down {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
}

.bagactivation-btn-grp {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  padding: 1rem 5rem 1rem 4rem;
}

.bag-image-upload {
  /* width: 120px;
  height: 120px;
  margin: 0rem 9rem 20rem -9rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  grid-gap: 0.9rem;
  gap: 0.9rem;
  /* display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginBottom: "5rem",
                      gap: "0.9em", */
}

#choose-profile-imageActi {
  display: none;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}
.key-activation input {
  padding: 0px !important;
}

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.bag-image {
  height: 256px;
  width: 256px;
  border-radius: 20%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.leftside-navigation {
  list-style: none;
  margin: auto;
  margin-left: -30px;
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline {
  border-bottom: 5px solid #fcbc1b;
}

textarea {
  min-height: 20px !important;
  border: 2px solid #e4e4e4;
  width: 200px !important;
  font-size: 14px;
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #e4e4e4 !important;
}

.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

p strong {
  font-size: 30px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
}

/* input[readonly] {
  background-color: white;
} */

.submit-login {
  width: 100%;
  max-height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

.multiple-images {
  transition: transform 0.3s;
}

.multiple-images:hover {
  transform: scale(2.5);
}

@media only screen and (max-width: 990px) {
  .body-login {
    width: 100%;
    margin-top: 50px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
    width: 100%;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .row-underline {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media (max-width: 1920px) {
  .bag-container {
    padding: 10vh 0 0 0vw;
  }

  /* Adjust height for "OtherKeys" */
  .bag-container.other-keys {
    height: 100vh; /* Reduced height for "OtherKeys" */
  }
}
@media (max-width: 1536px) {
  .bag-container {
    padding: 0vh 0 0 4vw;
    height: 155vh; /* Default increased height */
  }

  /* Adjust height for "OtherKeys" */
  .bag-container.other-keys {
    height: 120vh; /* Adjust this height for smaller screens */
  }
}
@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  .bag-container {
    margin-top: auto;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }

  .bag-activation-form {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
  .bag-container {
    margin-top: auto;
    height: auto;
  }
  .bag-activation-form {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  .input-pet-details-2 {
    width: 200px;
  }

  .input-pet-details-dropdown {
    width: 200px;
  }
  .submit-login {
    width: 200px !important;
  }

  .bag-activation-form {
    flex-direction: column;
    align-items: stretch;
    grid-gap: 0;
    gap: 0;
  }
  .bag-container {
    margin-top: auto;
  }
}
@media only screen and (max-width: 375px) {
  .bag-container {
    margin-top: 9rem;
  }
  .bag-activation-form {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}
@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    width: 160px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  .input-pet-details-2 {
    width: 170px;
  }

  .input-pet-details-dropdown {
    width: 170px;
  }
}

/* @media screen and (min-width: 48em) {
  .bag-activation-container {
    padding: 0 7em;
  }
} */

@media screen and (min-width: 62.5em) {
  .bagActivation-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .bagActivation-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 75em) {
  .bag-activation-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .bag-activation-form {
    padding: 0 10%;
  } */
}

/* Media query for small mobile screens */
@media (max-width: 768px) {
  .left-image {
    width: 100%; /* Change width to occupy the full width of the screen */
    /* Adjust other styles as needed for small screens */
  }
}

@media screen and (min-width: 78.125em) {
  .bagActivation-sidebar-menu-link {
    font-family: "prompt", sans-serif;
    font-size: 1rem;
  }

  .bagActivation-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }

  /* .bag-activation-form {
    padding: 0 20%;
  } */

  /* .bag-activation-container {
    margin: 2% 0;
  } */
}

@media only screen and (max-width: 600px) {
  .bag-container{
    margin-top: 0;
  }
  /* .editPetDetails-main-wrapper{
    top: 5.3rem;
  } */
}

.lockchnage_section{
  display: flex;
  width: 100%;
}
.lockchange_dummydiv{
  width: 50%;
}
.change_lock_btndiv{
  width: 50%;
}
.lock_div_bagdtls{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.keydetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}
.editPetDetails-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.keydetails-left-wrapper {
  position: relative;
  background-color: white;
}

.keydetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}
.keydetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.keydetails-right-form-btn-container {
  display: flex;
  /* justify-content: space-evenly;
   */
  padding: 0rem 4rem 0rem 3rem;
  grid-gap: 1em;
  gap: 1em;
}

.editPetDetails-user-img {
  text-align: center;
  position: relative;
}

.editPetDetails-image {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  /* border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
}

.editPetDetails-edit-btn-wrap {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}

.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  display: inline-block;
  padding: 5px 10px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  transition: background-color 0.3s;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  background-color: #fcbc1b;
  color: white;
}

.editPetDetails-input-user-img {
  display: none;
}

.editPetDetails-preview-img {
  margin-top: 10px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

/* p {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
} */

.keydetails-right-form-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em;
  width: 100%;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 4px 5px -3px rgba(0, 0, 0, 0.75);
}

.keydetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.keydetails-sidebar-container {
  width: 60%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.keydetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.keydetails-sidebar-menu-ul-hide {
  display: none;
}
.keydetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.keydetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
.background-container {
  /* background-image: url("../../assets/grey.png"); */
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-image-details {
  background: linear-gradient(to bottom, #fffdfd, #f4ebeceb, #f1f1f1cc);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
}
.keydetails-right-wrapper {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1em; */
}

.keydetails-right-wrapper > p {
  text-align: center;
}
.keydetails-right-form {
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
}
.keydetails-right-form-label-input-container {
  display: flex;
  flex-direction: column;
  /* gap: 0.5em; */
}

.keydetails-right-form-select,
.keydetails-right-form-inputs {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
}
/* Main Right Container */
.editPetDetails-right-wrapper {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  padding: 15px;
}
.editPetDetails-form-container-top {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 1rem;
  margin-top: 0rem;
}
.editPetDetails-user-img {
  text-align: center;
  position: relative;
}
.editPetDetails-image {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  
}
.editPetDetails-edit-btn-wrap {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}
.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  display: inline-block;
  padding: 5px 10px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  transition: background-color 0.3s;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  background-color: #fcbc1b;
  color: white;
}
.editPetDetails-input-user-img {
  display: none;
}
.two-field-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;
}

.editPetDetails-input-wrapper {
  width: 49%;
}
.editPetDetails-btn-row {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.editPetDetails-main-wrapper-shower {
  width: 100%;
  position: absolute;
  top: 11.3rem;
}
/* .editPetDetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 11.3rem;
  
} */
/* Main Container */
.editPetDetails-main-wrapper-shower {
  width: 100%;
  position: absolute;
  top: 11.3rem;
  
  
}
/* .editPetDetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 11.3rem;
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
  
} */
@media screen and (max-width: 360px) {
 
  .editPetDetails-main-wrapper-shower {
    /* width: min-content; */
    width: 100%;
  }
 
}
@media screen and (max-width: 480px) {
  .two-field-wrapper {
    flex-direction: column;
  }
  .editPetDetails-input-wrapper {
    width: 100%;
    margin: 8px 0px;
  }
}
@media only screen and (min-width: 660px) {
  .editPetDetails-form-container {
    flex-direction: row;
  }
 
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .editPetDetails-right-wrapper {
    width: 45rem;
    margin: 3rem 17rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 22rem;
  }
}
@media screen and (min-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 28rem;
  }
}
@media screen and (max-width: 360px) {
  
  .editPetDetails-right-wrapper {
    padding: 2.5rem;
  }
}
@media screen and (width: 64em) {
  .editPetDetails-right-wrapper {
    padding: 0 11% 0 23%;
  }
}
@media screen and (min-width: 48em) {
  /* .keydetails-right-wrapper {
    margin: 0 5rem;
    align-items: center;
  } */
  .keydetails-right-form-select,
  .keydetails-right-form-inputs {
    max-width: 15em;
  }
  .keydetails-right-form-btn-container {
    max-width: 25em;
  }
}
@media screen and (min-width: 62.5em) {
  .keydetails-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .keydetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
    font-family: "prompt", sans-serif;
  }
}
/* @media screen and (width: 64em) {
  .keydetails-right-wrapper {
    margin-top: 1.5em;
    margin-left: 15em;
  }
} */
/* @media screen and (min-width: 75em) {
  .keydetails-right-wrapper {
    margin-left: 17em;
    padding: 2% 11%;
  }
} */
@media screen and (min-width: 78.125em) {
  .keydetails-sidebar-menu-link {
    font-size: 1rem;
  }
  .keydetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
}

/* .body-login{
    width: 85%;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    margin-top: 15px;
  }
  
  .image-upload{
    width: 120px;
    height: 120px;
    margin-top:2rem ;
    margin-bottom: 3rem;
  }
  .add_img_btn{
    border: none;
    background-color:#e7e4e4;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 3rem;
  }
  .add_img_btn:hover{
    cursor: pointer;
    background-color: rgb(251 249 249);
  }

  #choose-profile-imageActi{
      display: none;
  }
  
  .key-activation input{
    padding: 0px !important;
  }
  
  select{
    padding: 0px !important;
  }
  
  .mat-raised-button:disabled{
    background-color:#FCBC1B;
  }
  
  .image{
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  
  .overlay{
    height: 120px;
    width: 120px;
    background-color: black;
    margin-top: -120px;
    position: absolute;
    opacity:0;
    border-radius: 50%;
  }
  .button-image{
    background-color: transparent;
    border: none;
    outline: none;
  }
  .fa-edit{
    margin-top:48px;
    color: white;
  }
  
  .plus-icon{
    margin-top: 0px;
    color: #a1a1a1;
  }
  
  .image-upload:hover .overlay{
    background-color: rgba(0,0,0,0.5);
    opacity:1;
  }

  .leftside-navigation{
    list-style: none; 
    margin: auto; 
    margin-left: -30px;
  }
  
  #uploadPreviewActi{
    width: 120px;
    height: 120px;
    margin-top: -148px !important;
    border-radius: 50%;
    border:none;
    outline:none;
  }
  
  #uploadPreview{
    width: 120px;
    height: 120px;
    margin-top: -140px !important;
    border-radius: 50%;
    display: none;
    border:none;
    outline:none;
  }
  
  
  .left-side{
    border-radius: 15px 0 0 15px;
    background-color: rgb(255, 234, 177);
  }
  
  .row-underline{
    border-bottom: 5px solid #FCBC1B;
  }

  textarea{
      min-height: 20px !important;
      border: 2px solid #e4e4e4;
      width: 200px !important;
      font-size: 14px;
      outline: none !important;
      box-shadow: none !important;
  }

  textarea:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid #e4e4e4 !important;
  }
  
  .input-pet-details{
    border: none;
    outline: none !important;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 200px;
    height: 20px;
    font-size: 14px;
  }
  
  .input-pet-details-1{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 440px;
    height: 20px;
    font-size: 14px;
  }
  
  .input-pet-details-2{
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
  }
  
  .input-pet-details-dropdown{
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
  }
  
  .label-petdata{
    font-size: 16px;
    color: #494949;
    font-weight: 800;
    font-family: "Lato", sans-serif;
  } */

/* .label-petdata-1{
  font-size: 12px;
  color: #777777;
  font-size: 16px;
    color: #494949;
    font-weight: 800;
    font-family: "Lato", sans-serif;
  }
  
  p strong{
  font-size: 18px;
  }
  
  .color-gray{
    color:  #b4b4b4;
  }
  
  ::ng-deep .mat-radio-label-content {
    font-weight: 550 !important;
  }
  
  input:focus{
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid #e4e4e4 !important;
    box-shadow: none !important;
    outline-width: 0 !important;
  }
  
  input:focus,
  select:focus,
  button:focus {
    outline: none !important;
  }
  
  input[readonly] {
    background-color: white;
  }
  
  .submit-login{
    width: 100%;
    max-height: 35px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .file{
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #FCBC1B;
    border-radius:5px;
    width:100px;
    height: 30px;
    font-size: 13px;
  }
  
  .gender-male{
      font-weight: none;
      font-size: 13px;
  }
  
  .gender-female{
  font-weight: none;
  font-size: 13px;
  }
  
  .multiple-images{
  transition: transform .3s;
  }
  
  .multiple-images:hover{
  transform: scale(2.5);
  }

  @media only screen and (max-width:990px){
    .body-login{
      width: 100%;
      margin-top: 50px !important;
    }
    .marginheight{
      margin-top: 0px !important;
    }
    .dashboard__blacksidenav{
      height: 100% !important;
      width: 100%;
    }
    .petparentlinks{
      margin-top: 20px !important;
      margin-left: -10px !important;
    }
  }
  
  
  @media only screen and (max-width:850px){
  .body-login{
    width: 100%;
  }
  }
  
  @media only screen and (max-width:770px){
  .label-petdata{
    text-align: center;
  }
  .label-petdata-1{
    text-align: center;
  }
  
  .gender-female{
    margin-top: -40px !important;
  }
  .below-gender-move{
    margin-top: 40px;
  }
  }
  
  @media only screen and (max-width:576px){
  .below-gender-move{
    margin-top: 0px;
  }
  .body-login{
    border-radius: 0px;
  }
  .left-side{
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons{
    margin-left: 0px !important;
  }
  .petparentlinks{
    margin-left: -10px !important;
    margin-top: 20px !important;
  }
  }

  @media only screen and (max-width:510px){
    .marginheight{
      margin-top: 30px !important;
    }
  }
  
  @media only screen and (max-width:360px){
    .input-pet-details{
      width: 200px;
    }
    
    .input-pet-details-2{
    width: 200px;
    }
    
    .input-pet-details-dropdown{
    width: 200px;
    }
    .submit-login{
      width: 200px !important;
    }
    }
    
    @media only screen and (max-width:330px){
      .gender-female{
        margin-top: -40px !important;
      }
      .below-gender-move{
        margin-top: 40px;
      }
      .leftside-buttons{
        width: 160px !important;
      }
      .petparentlinks{
        margin-left: -20px !important;
      }
      }
    
    @media only screen and (max-width:300px){
    .input-pet-details{
      width: 170px;
    }
    
    .input-pet-details-2{
    width: 170px;
    }
    
    .input-pet-details-dropdown{
    width: 170px;
    }
    }
   */

   .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  @media only screen and (max-width: 600px) {
    .bread_crumbs{
      margin-bottom: 2rem;
    }
    .bagActivation-left-wrapper{
      top: -4rem;
    }
    .bag-container{
      margin-top: 0;
    }
    /* .editPetDetails-main-wrapper{
      top: 8.3rem;
    } */
    .editPetDetails-main-wrapper-shower{
      top: 5.3rem;
    }
  }
  @media only screen and (max-width: 767px) {
       /* Your mobile-specific styles here */
   
    .editPetDetails-image {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2rem;
    
    }
}

@media only screen and (max-width: 767px) {
  .lock_div_bagdtls{
  
    flex-direction: column;
  }
  .change_lock_btndiv{
    width: 50%;
  }
  .lockchange_dummydiv{
    display: none;
  }

}
  
  
/* .header-section {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
/* z-index: 1000; */
/* }  */
.header-change-password-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
  margin: 1em;
}

.header-change-password-popup > label {
  margin: 0px !important;
  font-size: 25px;
  font-weight: bold;
}

.header-change-password-popup-input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.header-change-password-popup-checkbox-input-container {
  display: flex;
}

.header-change-password-popup-input {
  position: relative;
}

.header-change-password-popup-checkbox {
  display: none;
}

.header-change-password-popup-input {
  margin: 0px !important;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
  background-color: white !important;
}

.header-change-password-popup-checkbox-container > label {
  position: absolute;
  padding: 1em;
  right: 1.125em;
}

.header-change-password-popup-btns {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.header-change-password-popup-btns > button {
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
}

.header-section {
  position: fixed;
  height: 149px;
  background-color: #ffffff;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgb(167 167 167);
}
/* .header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 180px;
  z-index: 1000;
  transition: top 0.3s;
} */

.announcement-bar-1-hidden {
  display: none;
}
/* .announcement-bar-1 {
  background: rgb(var(--color-announcement-bar-background-1));
  color: #fff !important;
  text-align: center !important;
  border-bottom: 45px solid #000 !important;
  margin-top: -3.8rem !important;
  width: 105% !important;
  box-sizing: border-box !important;
} */
.announcement-bar-1 {
 position: -webkit-sticky;
 position: sticky; /* Keeps it visible at the top */
  top: 0;
  z-index: 1000; /* Ensures it stays above other elements */
  background: #252525f5;
  color: #fff;
  width: 100%; 
 
}

.announcement-bar__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.announcement-bar__message {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  grid-gap: 0.8rem !important;
  gap: 0.8rem !important;
  text-align: center !important;
  padding: 0.6rem 0.6rem !important;
  min-height: 1rem !important;
}
.announcement-bar__wrapper::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__border {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__text {
  font-weight: 400 !important;
  line-height: 145% !important;
}
.announcement-bar__text p {
  margin: 0 !important;
}
.announcement-bar__text p strong {
  /* margin: 0 !important; */
  font-size: 0.8rem !important;
}
a {
  position: relative;
  text-decoration: none;
  color: #000;
}
/* a :hover {
  color: #000;
} */
.header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 16px;
  /* left: 0; */
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.header-nav-ul-li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  right: 15px;
  color: black;
}

.header-nav-ul-li a:hover {
  color: black;
}
.profile-header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 3px;
  /* left: 0; */
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.profile-header-nav-ul-li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  right: 10%;
  color: black;
}

.profile-header-nav-ul-li a:hover {
  color: black;
}
.announcement-bar__text a {
  position: relative !important;
  color: inherit !important;
  text-underline-offset: 0.4rem !important;
  -webkit-text-decoration-color: inherit !important;
          text-decoration-color: inherit !important;
  z-index: 2 !;
}
.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}

.header-input__field__dialogs {
  padding: 0.375em 0.75em !important;
  border: 1px solid black;
  border-radius: 15px;
}

.header-verifyOtp-button {
  font-family: "prompt";
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.header-verifyOtp-button:hover {
  background-color: #ebb105;
}

.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.logo-container {
  text-align: center;
  margin: 1rem;
}

.logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.logo-image {
  max-width: 70%;
  height: auto;
  max-height: 60px;
}

.navbar-toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-top: -55px;
  position: relative;
  z-index: 3;
}

.header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-nav-hide {
  /* background: whitesmoke; */
  /* position: absolute; */
  width: 100%;
  /* top: 100%;
  right: -100%; */
  /* transition: 250ms; */
}

/* .header-nav-hide {
  background: #f5f5f5;
  position: absolute;
  top: 100%;
  transition: 0.35s;
} */
.header-nav-ul-li {
  margin-right: 10px;
}
.navbar__options span {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: -1rem;
}

.navbar__options {
  color: black;
  font-family: "prompt";
  font-size: 16px !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out;
  padding: 1.7rem 1rem !important;
  border: none;
  background: none;
}
.navbar__options:hover {
  color: black;
  text-decoration: none;
  cursor: default;
  outline: none;
}
.got-genie1 {
  height: 55%;
  padding: 10px;
  margin-right: 1rem;
  font-family: "prompt";
  border-radius: 10px;
  width: 165px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
  margin-right: 1rem;
}
.header-nav-hide .close-button {
  display: none;
}
/* .got-genie-top {
  color: #00000000;
} */

.got-genie-div-1 {
  display: none;
  margin-left: auto;
  align-items: center;
  overflow: hidden;
  float: left !important;
  outline: none;
}

.got-genie-form {
  margin: 5px;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  background-color: #24292d;
  border: 1px solid #504d4d;
  border-radius: 10px;
}

.got-genie-form > input {
  width: 70%;
  height: 30px;
  align-self: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.genie-form-buttons {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
}

.genie-form-buttons > button {
  background-color: white;
  padding: 5px 13px;
  margin: 10px 8px;
  margin-top: 12px;
  border-radius: 5px;
}
.genie-form-buttons > button:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.genie-form-buttons > button:hover:disabled {
  cursor: inherit;
}

.genie-form-buttons > button:disabled {
  background-color: #d3d3d3;
}

/* .nav-link {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
} */

@media screen and (max-width: 2000px) {
  .navbar-toggle-btn {
    display: none;
  }
  .header-nav-ul {
    list-style: none;
    /* padding: 0; */
    /* margin: 0; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
  }
  /* .header-nav-hide {
    width: fit-content;
    display: flex;
    top: 0;
    right: 0;
  } */

  /* .header-nav-ul {
    display: flex;
    align-items: center;
    height: 3.3em;
    max-width: 60em;
  } */
}

@media (max-width: 768px) {
  /* .header-nav-ul.show {
    display: flex; /* Show the options when the show class is applied */
  /* } */
  .header-nav-ul {
    list-style: none;
    display: flex;
    flex-direction: column; /* Change direction to column for smaller screens */
    align-items: baseline;
  }

  .header-nav-ul-li a::before {
    display: none; /* Hide the pseudo-element on smaller screens */
  }

  .header-nav-ul-li a:hover::before {
    transform: scaleX(
      0
    ); /* Ensure the transform is reset for smaller screens */
  }
  .navbar-toggle-btn {
    display: block; /* Show the button */
  }
  .header-section {
    height: 65px;
    position: fixed !important;
    background-color: #ffffff;
    /* top: 40px; Ensure the header stays at the top */
    z-index: 1000;
  }
  /* .announcement-bar-1 {
    width: 100%;
  } */

  .logo-container {
    text-align: left;
    margin: 0.8rem 0rem 0.5rem 3rem;
    width: 9rem;
  }
  .announcement-bar__message {
    padding: 0.8rem 1.6rem !important;
  }
  /* .header-nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: hsl(0, 13%, 89%);
    padding-top: 50px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .header-nav .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 24px;
    color: #333;
    outline: none;
    transition: color 0.3s ease;
  }

  .header-nav .close-button:hover {
    color: #ff0000;
  }

  .header-nav {
    width: 60%;
    height: 100vh;
    position: fixed;
    top: 5.8%;
    right: 0;
    background-color: #ffffff;
    padding-top: -6px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  .header-nav ul {
    list-style: none;
    padding: 0;
    margin: 0; /* Resetting margin to zero */
  }

  .header-nav-ul-li,
  .profile-header-nav-ul-li {
    position: relative;
    margin: 0; /* Ensure no extra margin is added to the list items */
    padding: 0.5rem 0; /* Adding some padding for spacing */
    border-bottom: 1px solid #333; /* Adding a bottom border */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }

  /* Remove the pseudo-element as we are using border-bottom */
  .header-nav-ul-li:after,
  .profile-header-nav-ul-li:after {
    content: none;
  }

  .header-nav-ul-li:last-child,
  .profile-header-nav-ul-li:last-child {
    border-bottom: none; /* Remove bottom border for the last item */
  }

  /* Optional: Style for the anchor tags inside the list items */
  .header-nav-ul-li a,
  .profile-header-nav-ul-li a {
    display: block; /* Ensure the anchor tags take the full width of the list item */
    padding: 0.5rem 1rem; /* Add some padding for spacing */
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Ensure the color is inherited from the parent */
  }

  .navbar__options {
    padding: 0.4rem 0rem !important;
  }
  .header-nav li a {
    padding: 10px; /* Adjust padding as needed */
    text-decoration: none;
    color: #333;
  }

  .header-nav-hide {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-out;
  }
  /* .header-nav-ul-li a::before {
    display: none;
  } */
}
/* @media (max-width: 767px) {
  .logo-container,
  .navbar-toggle-btn {
    display: block;
  }
} */

@media (max-width: 360px) {
  .header-nav {
    top: 9%;
  }
}

.footer {
  width: 100%;
  height: 20px;
  background-color: #272730;
  text-align: center;
  color: white;
  padding-top: 1px;
  padding-left: 15px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.footer .left-content {
  flex: 1 1;
}

.footer .right-content {
  flex: 1 1;
  text-align: center;
}
.footer .left-content {
  flex: 1 1;
}

.footer .right-content {
  flex: 1 1;
  text-align: center;
}
.footer-div {
  width: 100%;
  height: -webkit-max-content !important;
  height: max-content !important;
  background-image: linear-gradient(to right, #ccad5e, #fdcb4d);
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
}

.online-store {
  text-align: center;
  margin-right: 10px;
}

.online-store img {
  height: 60px !important;
  border-radius: 10px;
  box-shadow: 3px 3px 6px grey;
}

.footer-div-3 {
  height: 200px;
  text-align: center;
}

footerdiv3 {
  margin-top: 40px !important;
}

.footer-div-3 div {
  text-align: center !important;
  margin-top: 10px !important;
}

.footer-div4-1 {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
}

.footer-div4-1 button {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}
.footer-div4-2 {
  padding: 10px 5px;
  display: flex;
  flex-direction: "row";
  margin-top: -5px;
}
.footer-div4-2 button {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}

.link-btn {
  width: 180px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

.link-btn1 {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

/* Media Queries */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */

@media screen and (max-width: 768px) {
  .footer {
    height: 65px;
    margin: auto;
  }
  .footer-div-3 div {
    text-align: left !important;
  }

  .link-btn {
    width: 110px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }

  .link-btn1 {
    width: 150px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 400px) {
  .online-store img {
    height: 60px !important;
    width: 90px !important;
  }
  .footer {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
  }
  .footer .left-content,
  .footer .right-content {
    font-size: 10px;
  }
}

.app {
    position: relative;
  }
  .app.sidebar-open .content {
    filter: blur(5px);
  }
  .toggle-button,
  .mobile-toggle-button {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1050;
    background: none;
    border: none;
    font-size: 24px;
    background-color: #ffffff4d;
    border: 0.75px solid #000 !important;
    border-radius: 4px;
    padding: 4px;
    /* transition: left 0.3s ease; */
  }
  .sidebar-icons {
    width: 1.75rem;
  }
  .sidebar-sub-icons {
    width: 1rem;
  }
  .toggle-button:focus,
  .mobile-toggle-button:focus {
    outline: none !important;
    border: 0.75px solid #000 !important;
  }
  
  .sidebar {
    position: fixed;
    top: 190px;
    left: 0;
    width: 60px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* transition: width 0.3s ease; */
    z-index: 1040;
  }
  .app.sidebar-collapsed .toggle-button {
    left: 175px;
  }
  .sidebar.collapsed {
    width: 220px;
  }
  
  .sidebar-menu {
    padding: 60px 0 0 0;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    padding: 18px 16px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .menu-item span {
    display: none;
    margin-left: 10px;
  }
  .menu-item svg {
    width: 20px;
    height: 30px;
  }
  .sidebar.collapsed .menu-item span {
    display: inline;
  }
  
  .submenu {
    padding-left: 20px;
  }
  
  .submenu .menu-item {
    padding: 10px 0;
  }
  
  .sidebar-logo {
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .shop-now-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding: 26rem 3rem 0rem 3rem;
  }
  
  .shop-now-button {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .shop-now-button:hover {
    background-color: black;
    color: white;
  }
  
  @media (max-width: 768px) {
    .toggle-button {
      display: none;
    }
  
    .mobile-toggle-button {
      left: 20px;
      top: 80px;
      padding: 0px;
    }
    .app.sidebar-collapsed .mobile-toggle-button {
      left: 200px;
      top: 80px;
    }
    .sidebar {
      top: 140px !important;
      width: 0;
      height: 100%;
      /* transition: width 0.3s ease; */
    }
  
    .sidebar.collapsed {
      width: 230px;
    }
  
    .app.sidebar-collapsed .sidebar {
      /* top: 0; */
      left: 0;
    }
  }
  
  @media (min-width: 361px) and (max-width: 768px) {
    .sidebar {
      top: 112px !important;
    }
  
    .app.sidebar-collapsed .sidebar {
      top: 116px !important;
    }
  }
  
/* Yashvi key css start */
body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}
/* claim form css */
.step_div{
  background-color: lightblue;
  padding: 1rem;
}
.claim_remarks{
  text-align: center;
  font-size: small;
  display: block;
  font-weight: bold;
}
.form_div{
  margin: 1rem;
}
.step_heading{
  margin-bottom: .5rem;
}
@media only screen and (max-width: 350px) {
    /* CSS rules for mobile screens specfic for galaxy s8 */
    .form_div{
  margin: 0;
}
}
@media only screen and (max-width: 768px) {
    /* CSS rules for mobile screens */
    .claim_remarks {
        display: inline;
        font-size: xx-small;
        /* Any other mobile-specific styles */
    }
}

/*  claim form css end  */

.reportlost-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  /* margin-top: 1rem; */
  height: 100%;
}
.date-time-loss {
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
  align-items: center;
}

.reportlost-one-line {
  display: flex;
  flex-direction: column;
  grid-gap: 1.7em;
  gap: 1.7em;
}
.reportlost-location {
  position: relative;
  height: 30em;
}
.reportlost-location-map-container {
  position: absolute;
  width: 100%;
  height: 16.5em;
}
.display-currency-type {
  display: flex;
}
.time-picker-container {
  display: flex;
  flex-direction: row;
  grid-gap: 1em;
  gap: 1em;
  width: 55%;
  margin: 0rem 0rem 0rem 9rem;
}
.time-picker-labelcss {
  margin-top: -4rem;
  margin-right: -9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#reward-id {
  width: 183px;
}
.custom-textfield {
  width: 80%;
  height: 150px;
  font-size: 16px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8px;
  outline: none; /* Remove the outline when focused */
}
.reportlost-bag-container {
  background-color: #c7c7c781;
  width: 100%;
  max-width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 11rem; */
}
.reportlost-container-pet-details-span,
.reportlost-container-pet-parent-details-span,
.reportlost-container-owner-reward-span {
  display: flex;
  flex-direction: column;
  grid-gap: 1.7em;
  gap: 1.7em;
}

.reportlost-container-pet-details-span > h4,
.reportlost-container-pet-parent-details-span > h4,
.reportlost-container-owner-reward-span > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.4em;
  gap: 0.4em;
  margin-top: 1em;
}

.reportlost-container-pet-details-span-input-cnt,
.reportlost-container-pet-parent-details-span-input-cnt,
.reportlost-container-owner-reward-span-select-cnt {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.reportlost-container-pet-details-span-input-cnt > span,
.reportlost-container-pet-parent-details-span-input-cnt > span,
.reportlost-container-owner-reward-span-select-cnt > span {
  text-align: left;
  font-size: 1.25rem;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-parent-details-span-input-cnt > input,
.reportlost-container-pet-details-span-input-cnt > select,
.reportlost-container-owner-reward-span-select-cnt > select,
.reportlost-container-owner-reward-span-select-cnt > input {
  padding: 1rem 2rem !important;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-parent-details-span-input-cnt > input,
.reportlost-container-owner-reward-span-select-cnt > input {
  margin-top: 0 !important;
}

.reportlost-location-input-cnt {
  display: none;
  /* position: absolute;
  top: 17em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em; */
}

.reportlost-location-input-cnt-lat,
.reportlost-location-input-cnt-lon {
  display: none;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.reportlost-location-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 2rem 2rem 0rem 6rem;
}
.reportlost-container-owner-reward-btns {
  /* margin: 1em 0; */
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.date-time-loss-input {
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}

.date-time-loss-time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.date-time-loss-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 14px;
  padding: 10px;
  margin-top: 0.5rem;
}
.input-pet-details-dropdown > option {
  padding: 10px;
  font-size: 0.9rem;
}
.background {
  min-height: 100% !important;
  height: 100%;
}

.ngx-timepicker-field-example {
  height: 30px !important;
  font-size: 25px !important;
}

.lostincipet {
  text-transform: none !important;
}

.mat-button-toggle {
  width: 200px;
  border: none;
  outline: none;
  word-wrap: break-word;
}

.mat-button-toggle-group {
  border: none;
  outline: none;
}

.mat-button-toggle-group:focus .mat-button-toggle-group:checked {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle:focus .mat-button-toggle:checked {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle-button:focus .mat-button-toggle-button:checked {
  outline: none !important;
  border: none !important;
}

.gray-color {
  color: lightgray;
}

.bg-gray {
  background-color: lightgrey;
}

.profile-sidenav {
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400px;
}
.profile_name {
  padding-left: 5rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  margin-top: 2rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.user_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.user_img_icon {
  width: 5rem;
  height: 5rem;
}

.p-info {
  width: 85%;
  border-radius: 5px;
}

.lightgray-color {
  background: #e9eaed;
}

.fa-user-circle-o {
  background: #ebebeb;
  color: rgb(211, 209, 209);
  border-radius: 50%;
  border: none;
  outline: none;
  margin-top: -10px;
}

.full-name {
  font-size: 22px;
  font-weight: 350px;
  margin-top: -10px;
}

.full-name-1 {
  font-size: 17px;
  font-weight: 350px;
}

.full-name-2 {
  font-size: 17px;
  font-weight: 350px;
}

.fullname-email {
  font-size: 17px;
  font-weight: 350px;
  margin-top: -23px;
}

.full-nickname {
  margin-top: 50px;
}

.nickname {
  margin-left: 40%;
}

.nickname-1 {
  margin-left: 20%;
}

.h-100 {
  height: 100%;
}

.edit-button {
  margin-left: 35%;
  color: rgb(211, 209, 209);
  border: none;
  outline: none;
  background-color: white;
}

.mobile-update {
  width: 250px;
}

.googlemaps {
  width: 60% !important;
  height: 300px !important;
}

.latlong {
  padding-top: 290px;
}

.loss-details-header {
  width: 68%;
  color: white;
  margin-left: 15%;
  border: 1px solid black;
}
.loss-details-body {
  width: 80% !important;
  margin-left: 15%;
  border: 1px solid black;
  word-wrap: break-word;
  text-overflow: clip;
  border-radius: 1rem;
}
.know-where-lost {
  font-size: 16px;
}

.reportlost-container-currency {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 1.7em;
  gap: 1.7em;
}
.fa-map-marker-alt {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.fa-calendar-alt {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.fa-clock {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.reward-header {
  letter-spacing: 1px;
}

.reward-body {
  font-size: 17px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

button:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: white;
}

button:hover {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

#lost-calendar-preview {
  width: 250px;
  margin-left: 35%;
}

.text-gray {
  color: #b4b4b4;
}

.inline-display {
  display: inline-block;
}

.lost-time {
  margin: auto;
  width: 200px;
}

.keyreportlost-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}
.keyreportlost-left-wrapper {
  position: relative;
}
.keyreportlost-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}
.keyreportlost-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.keyreportlost-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.keyreportlost-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.keyreportlost-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.keyreportlost-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.keyreportlost-sidebar-menu-ul-hide {
  display: none;
}
.keyreportlost-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
/* input{
    padding: 0px !important;
  } */

/* @media only screen and (max-width:1310px){
    .mat-button-toggle{
        width: 150px;
    }
} */

.petparentlinks-rl {
  margin-left: -30px !important;
}

@media screen and (min-width: 62.5em) {
  .keyreportlost-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .keyreportlost-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
    font-family: "prompt";
  }
}

@media screen and (min-width: 48em) {
  .reportlost-one-line {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (width: 64em) {
  .keyreportlost-right-wrapper {
    margin-left: 11em;
  }
}

@media screen and (min-width: 75em) {
  .keyreportlost-right-wrapper {
    margin-left: 20vw;
  }
}
@media only screen and (max-width: 960px) {
  /* .mat-button-toggle{
        width: 100px;
    } */
  .mat-button-toggle-group {
    flex-wrap: wrap;
    margin: auto !important;
  }
  .googlemaps {
    width: 60% !important;
    height: 200px !important;
  }

  .latlong {
    padding-top: 200px;
  }

  .dateandtime {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .mat-toggle-group-original {
    margin-top: -20px !important;
  }
  .mat-button-toggle {
    width: 150px !important;
    font-size: 14px !important;
  }
  .rightbackground {
    min-height: 1000px !important;
    height: 1000px !important;
  }
}
@media only screen and (max-width: 991px) {
  .text-center {
    margin-top: -7rem;
  }
}

@media only screen and (max-width: 804px) {
  .angularlocationmap {
    height: 200px !important;
  }
}

@media only screen and (max-width: 800px) {
  #lost-calendar-preview {
    margin-left: 25%;
  }
  .mat-button-toggle {
    width: 120px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 747px) {
  .marginheight-rl {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 700px) {
  .leftside-buttons-rl {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 650px) {
  #lost-calendar-preview {
    margin-left: 15%;
  }

  .reportlostheight {
    height: 100% !important;
  }

  .mat-toggle-group-original {
    margin-top: 00px !important;
  }
}

@media only screen and (max-width: 576px) {
  #lost-calendar-preview {
    margin-left: 20%;
  }
  .marginheight-rl {
    margin-top: 15px !important;
  }
  .uppercontainer {
    margin-left: 8px !important;
    width: 100% !important;
    margin-right: 0px !important;
  }
  .reportlost-bag-container {
    margin: auto;
    height: auto;
    margin-top: 13rem;
  }
}

@media only screen and (max-width: 480px) {
  .loss-details-body {
    width: 100% !important;
    margin: auto;
  }
  .nextback {
    height: 35px !important;
    width: 200px !important;
  }
  .uppercontainer {
    width: 95% !important;
    margin-right: -10px !important;
  }
}

@media only screen and (max-width: 450px) {
  #lost-calendar-preview {
    margin-left: 15%;
  }

  .mat-raised-button {
    width: 200px !important;
  }
}

@media only screen and (max-width: 415px) {
  .mat-button-toggle {
    width: 160px !important;
    font-size: 14px !important;
    height: 40px !important;
  }
}
@media only screen and (max-width: 510px) {
  .reportlost-bag-container {
    margin: auto;
    height: auto !important;
    /* margin-top: 13rem; */
  }
  .date-time-loss-input {
    width: 100%;
  }
  .time-picker-container {
    display: block;
    grid-gap: 1em;
    gap: 1em;
    margin: auto;
  }
  .date-time-loss {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    align-items: normal;
  }
}
@media only screen and (max-width: 400px) {
  #lost-calendar-preview {
    margin-left: 10%;
  }

  .lost-time {
    width: 100%;
  }

  .ngx-timepicker-field-example-col {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 380px) {
  .timerangeofloss {
    margin-top: -30px !important;
  }
  .googlemaps {
    width: 60% !important;
    height: 130px !important;
  }
  .latlong {
    padding-top: 130px;
  }
  .reward_div {
    margin-left: -40px !important;
  }
  .set-time {
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 350px) {
  #lost-calendar-preview {
    margin-left: 15%;
    width: 200px;
  }

  .inline-display-1 {
    display: inline-block;
  }

  .rewardcss {
    width: 200px !important;
  }

  .set-time {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 312px) {
  #lost-calendar-preview {
    margin-left: 10%;
    width: 200px;
  }

  .lost-time {
    width: 100%;
  }
  .ngx-timepicker-field-example-col {
    margin-left: -60px !important;
  }

  .reward_div {
    margin-left: -55px !important;
  }

  .set-time {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 360px) {
  .reportlost-bag-container {
    margin: auto;
    height: auto !important;
    /* margin-top: 13rem; */
  }
  .date-time-loss-input {
    width: 100%;
  }
  .time-picker-container {
    display: block;
    grid-gap: 1em;
    gap: 1em;
    margin: auto;
  }
  .date-time-loss {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    align-items: normal;
  }
}
@media only screen and (max-width: 375px) {
  .reportlost-bag-container {
    margin: auto;
    height: auto !important;
    /* margin-top: 13rem; */
  }
  .date-time-loss-input {
    width: 100%;
  }
  .time-picker-container {
    display: block;
    grid-gap: 1em;
    gap: 1em;
    margin: auto;
  }
  .date-time-loss {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    align-items: normal;
  }
}
@media only screen and (max-width: 290px) {
  #lost-calendar-preview {
    margin-left: 8%;
  }

  .mat-raised-button {
    width: 150px !important;
  }

  .ngx-timepicker-field-example-col {
    margin-left: -65px !important;
  }

  .reward_div {
    margin-left: -50px !important;
  }

  .reward_div input,
  .reward_div select {
    width: 180px !important;
  }

  .set-time {
    font-size: 13px !important;
    margin-left: -35px !important ;
  }
}

@media screen and (max-width: 718px) {
  .reportlost-container-currency {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 1.7em;
    gap: 1.7em;
  }
}

@media screen and (max-width: 729px) {
  #reward-id {
    width: 230px;
  }
}
@media screen and (min-width: 22.5em) {
  .reportlost-container-owner-reward-btns {
    margin: 1em 3em;
    /* margin: 1em 3em; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 1em;
    gap: 1em;
  }
}

/* ----------------------------- */

.profile__leftPanel {
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  margin: 30px 1px;
  margin-left: 0;
  flex: 0.25 1;
  background-color: #343a40;
  color: white;
}
.fa-user-circle {
  font-size: 100px;
  color: white;
  margin-bottom: 5px;
}
.profile__button {
  color: white;
  text-decoration: none;
  align-self: baseline;
  margin-left: 0;
  font-size: 14px !important;
}
.profile__button:hover {
  text-decoration: none;
  color: white;
  background-color: #32383e;
}
.profile__subButtons {
  color: white;
  align-self: baseline;
  margin: 0 20px;
  font-size: 14px;
  padding: 10px;
}
.profile__subButtons:hover {
  text-decoration: none;
  color: white;
  background-color: #32383e;
}




/* @import url("https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Federant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gorditas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap");
@import url("https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB");
@import url("https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT");
@import url("https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont"); */

.bag-poster-toggle-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.bagposter-container {
  background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a 3D effect */
  /* padding: 20px; */
  width: 100%;
  max-width: 1200px;
  /* height: 100vh; */
  margin: auto;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
}

.key-lost-detail {
  margin-top: 1em;
  text-align: center;
  border: 0.5px solid gray;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  transition: all 0.5s;
  bottom: -17.5rem;
  width: 100%;
  padding: 5%;
  padding-bottom: 1rem;
}

.keyposter-lost-details-span {
  width: 100%;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.keyposter-lost-details {
  padding: 1rem;
  border: 0.5px solid gray;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.keyposter-lost-details label,
.keyposter-lost-details p {
  margin-bottom: 0px !important;
}
.poster-container {
  border: 2px solid #000;
  padding: 20px;
  border-radius: 8px;
}
/* .poster-msg-wrapper-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
} */

.poster-msg-wrapper {
  margin-bottom: 16px;
  text-align: center;
}

.poster-msg {
  font-weight: bold;
  margin-bottom: 8px;
}

.poster-owner-details {
  text-align: center;
}

.poster-owner-name {
  font-weight: bold;
  margin-bottom: 8px;
}

.poster-owner-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: white;
}
.custom-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/* .poster-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
} */

.poster-reward-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.poster-reward-currency {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.poster-reward-currency svg {
  margin-right: 4px;
}

.keyposter-lost-details p {
  font-weight: bold;
  font-size: 20px;
}

.genie-nav {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 1000;
  background-color: white;
}

.poster-utility {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  z-index: 999;
}

.poster-utility-btns {
  /* width: 100%; */
  border: none;
  border-radius: 5px;
  padding: 1em;
  background-color: whitesmoke;
  text-align: center;
  cursor: pointer;
}
.poster-utility-btns:hover {
  text-decoration: none;
}
.header-font-key {
  font-family: "Prompt", sans-serif !important;
  /* padding: 2rem; */
  color: black;
}

.bottom-inner-div {
  display: flex;
  padding: 2rem;
  justify-content: space-around;
}
.reportfound-message {
  padding: 1em;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.reportfound-message-header {
  letter-spacing: 0.125em;
  font-size: 144%;
  font-family: "Acme", sans-serif;
  margin: 0px !important;
}

.reportfound-message-content {
  letter-spacing: 0.25em;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  margin: 0px !important;
}

.reportfound-message-content-icon {
  font-size: 1.875rem;
}

.reportfound-message-content-name {
  letter-spacing: 1px;
  font-size: 1.625rem;
  font-family: "Prompt", sans-serif;
}

.reportfound-message-ownerid {
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin: 0px !important;
}

#aup,
#adown {
  display: none;
}
a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.pabr {
  border: 0.5px solid gray;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}
.footer-font img {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}

.footer-font {
  font-family: "Prompt", sans-serif;
  margin-bottom: 0px !important;
}
.vector {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}
.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}
.img-container {
  text-align: center;
}
.mat-card-image2 {
  border-radius: 100px;
  height: 180px;
  width: 190px;
  margin: auto;
  background-color: white;
}

.header-text-key {
  font-size: 50px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
  line-height: 70px;
}

.key-mat-card {
  width: 80%;
  margin: auto;
  margin-top: 90px;
  /* border: 1px solid #cca151; */
  box-shadow: none !important;
}

.mat-card-header {
  margin-left: 18%;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
  line-height: normal;
  padding-top: 10px;
  padding-right: 8px;
}

.lost-info-text-key {
  font-size: 21px;
  border: 0.5px solid gray;
  padding: 1.2vw;
  width: 23vw;
  border-radius: 10px;
  font-family: "Prompt", sans-serif;
}

.lost-info-text-address-key {
  font-size: 21px;
  margin: 2rem;
  padding: 1.5rem;
  border: 0.5px solid gray;
  border-radius: 10px;
}
.answers-to-name {
  width: 100%;
  background-color: rgb(102, 89, 32);
  color: white;
  margin: auto;
}

.answers-to-name-header {
  font-family: "Prompt", sans-serif;
  font-size: 25px;
  transform: scale(1.6, 1.4);
}

.answers-to-name-text-1 {
  font-family: "Prompt", sans-serif;
  font-size: 22px !important;
  transform: scale(1.3, 1.1);
  font-weight: 400 !important;
  word-wrap: break-word !important;
}

.answers-to-name-text {
  font-family: "Prompt", sans-serif;
  font-size: 25px;
  transform: scale(2.5, 2);
  font-weight: 700;
}

.bottom-help-text {
  font-size: 17px;
  transform: scale(1, 1);
}

/* .bottom-text {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  transform: scale(1.2, 1.6);
}

.bottom-text-1 {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  transform: scale(1.6, 1.6);
} */

.reward-header {
  font-size: 25px;
  transform: scale(1.5, 1.8);
}

.yellow-color {
  color: rgb(202, 167, 82);
}

.button-styling {
  margin: 10px;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}
.poster-msg-wrapper-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
}

.poster-msg-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.poster-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.poster-msg > p {
  margin: 0px !important;
  font-size: 1.6rem;
  margin: 20px 0px;
  color: #f6be07;
  font-family: "Prompt", sans-serif;
}

.poster-owner-details {
  padding: 0.0625em 1.875em;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-owner-name {
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

.poster-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  grid-gap: 6px;
  gap: 6px;
}

.poster-info-wrapper p {
  margin-bottom: 0px !important;
  color: #f6be07;
  font-size: 20px;
}

.poster-owner-phone {
  display: flex;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

.poster-reward {
  padding: 0.0625em 1.875em;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-reward-title {
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

.poster-reward-currency {
  display: flex;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

@media only screen and (max-width: 1120px) {
  .answers-to-name-header {
    font-size: 18px;
  }
  .lost-info-header-res {
    font-size: 28px;
  }
}
@media only screen and (max-width: 922px) {
  .answers-to-name-header {
    font-size: 15px;
  }
  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }
  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }
  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }
  .lost-info-text-key {
    width: 20vw;
    /* padding: 1rem; */
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 768px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }
  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
  }
  .button-styling {
    width: 100% !important;
  }
  .marginleftmove {
    margin-left: -20px !important;
    text-align: center !important;
  }
  .marginleftmove1 {
    margin-left: 10px !important;
  }
  .lost-data-header {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 690px) {
  .header-text-key {
    line-height: 4rem;
    font-size: 40px;
    padding: -1rem 8rem 0rem 8rem;
    margin-top: 5rem !important;
  }
  #aup {
    display: block;
  }
  .answers-to-name-header {
    font-size: 8px;
  }
  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }
  .key-lost-detail p {
    padding-bottom: 1rem;
    font-size: 16px !important;
    font-weight: 100 !important;
  }

  .bottom-inner-div {
    flex-direction: column;
    justify-content: center;
  }

  .main-img2 {
    text-align: center;
    padding-top: 6vh;
    position: absolute;
    top: -120px;
    left: 48%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
  .key-mat-card {
    border: none;
    width: 100%;
    margin: 0;
    margin-bottom: 13rem !important;
  }

  /* .colorw {
    background-color: white;
  } */
  .lost-valuable {
    /* margin-top: 30px !important; */
    margin-bottom: 0px !important;
  }
  .background {
    background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
    /* background-image: url("../../assets/seamless-pattern-bags-doodle-style-hand-drawn-woman-accessories-background-vector-black-white-design.jpg"); */
  }
  .img-container {
    text-align: left;
    padding: 6rem;
    padding-top: 0;
    /* background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c); */
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .mat-card-image2 {
    height: 160px !important;
    width: 160px !important;
    border: 5px solid white;
  }
  .bottom-inner-div {
    padding: 0;
  }
  .footer-font img {
    max-width: 30px;
    max-height: 30px;
    margin-left: 2px;
    margin-right: 8px;
  }

  .vector {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .lost-info-text-key {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 43vw;
    /* height: 10vh; */
  }
  .header-font-key {
    padding-bottom: 6rem;
  }
  .footer-font {
    text-align: left;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    grid-gap: 2px;
    gap: 2px;
  }
}
.poster-msg-title {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Prompt", sans-serif;
  color: #cca151;
}

@media only screen and (max-width: 500px) {
  .lost-data-header {
    font-size: 35px !important;
  }
  .main-img2 {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 45%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .key-lost-detail {
    position: fixed;
    bottom: -17.5rem;
    padding: 5%;
    padding-bottom: 2vh;
  }
}

@media only screen and (max-width: 450px) {
  .poster-utility {
    flex-direction: column;
    position: absolute;
    background-color: white;
    transition: all ease-in-out 500ms;
  }

  .poster-utility-hide {
    display: none;
    transition: all ease-in-out 500ms;
  }

  .main-img2 {
    text-align: center;
    position: absolute;
    top: -175px;
    left: 42%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .key-lost-detail {
    position: fixed;
    bottom: -14.5rem;
    padding: 5%;
    padding-bottom: 2vh;
    border: 0.5px solid gray;
    border-radius: 20px 20px 0 0;
  }
  .lost-info-text-key {
    width: 48vw;
  }
}

@media only screen and (max-width: 400px) {
  .key-mat-card {
    width: 100%;
  }
  .header-text-key {
    line-height: 2.5rem;
  }
  .lost-info-header {
    font-size: 20px;
  }

  .answers-to-name {
    padding: 40px !important;
  }

  .answers-to-name-header {
    font-size: 14px !important;
  }
  .answers-to-name-text {
    font-size: 15px;
  }
  .bottom-text-1 {
    font-size: 18px;
  }
  .reward-header {
    padding: 10px 0px 0px 0px;
  }
  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }
  .reward-header {
    font-size: 20px !important;
  }
  .answers-to-name-text-1 {
    font-size: 18px !important;
  }
  .marginleftmove1 {
    margin-left: -5px !important;
  }

  .key-lost-detail {
    padding: 5%;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }
  .button-styling {
    margin: 12px;
    font-size: 15px;
    padding: 10px;
  }
  .key-mat-card {
    width: 100%;
  }
  .lost-data-header {
    font-size: 55px;
    text-align: center;
    margin-top: 30px;
  }

  .key-lost-detail {
    bottom: -17.5rem;
  }
}

@media (max-width: 768px) {
  .poster-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .poster-container {
    border-width: 1px;
    padding: 0rem 0rem;
    border-radius: 4px;
  }
}
@media screen and (min-width: 37.5em) {
  .bag-poster-toggle-btn {
    display: none;
  }
}

@media screen and (min-width: 48em) {
  .reportfound-message-header {
    letter-spacing: 1px;
  }
  .bg-gray {
    background-color: transparent !important;
  }

  .keyposter-lost-details-span {
    grid-gap: 1em;
    gap: 1em;
  }
}

@media screen and (min-width: 50em) {
  .keyposter-lost-details-span {
    width: 36em;
  }
}

@media screen and (width: 51.25em) {
  .reportfound-message-header {
    letter-spacing: 1px;
  }
}

@media screen and (width: 22.5em) {
  .header-font-key {
    padding-bottom: 6rem;
  }
}

@media (max-width: 767px) {
  .col-md-2 {
    width: 100%;
    padding: 2rem 2rem 1rem 3rem;
  }
}
@media (min-width: 350px) and (max-width: 450px) {
  .container-for-rows {
    grid-gap: 10px;
    gap: 10px; /* Reduce the gap between rows on smaller screens */
  }
  .custom-container {
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .custom-container img {
    max-width: 100%; /* Make the image responsive and fit the container width */
    height: auto; /* This will maintain the aspect ratio */
  }
}

.body-login {
  width: 85%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}

.image-upload {
  width: 120px;
  height: 120px;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.add_img_btn {
  border: none;
  background-color: #e7e4e4;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 3rem;
}
.add_img_btn:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}

#choose-profile-imageActi {
  display: none;
}

.key-activation input {
  padding: 0px !important;
}

/* select{
    padding: 0px !important;
  } */

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent;
  border: none;
  outline: none;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.leftside-navigation {
  list-style: none;
  margin: auto;
  margin-left: -30px;
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline {
  border-bottom: 5px solid #fcbc1b;
}

textarea {
  min-height: 20px !important;
  border: 2px solid #e4e4e4;
  width: 200px !important;
  font-size: 14px;
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #e4e4e4 !important;
}

.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

.label-petdata-1 {
  /* font-size: 12px;
  color: #777777; */
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
}

/* input[readonly] {
  background-color: white;
} */

.submit-login {
  width: 100%;
  max-height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

.multiple-images {
  transition: transform 0.3s;
}

.multiple-images:hover {
  transform: scale(2.5);
}

@media only screen and (max-width: 990px) {
  .body-login {
    width: 100%;
    margin-top: 50px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
    width: 100%;
  }
  .petparentlinks {
    margin-top: 20px !important;
    margin-left: -10px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -10px !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  .input-pet-details-2 {
    width: 200px;
  }

  .input-pet-details-dropdown {
    width: 200px;
  }
  .submit-login {
    width: 200px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    width: 160px !important;
  }
  .petparentlinks {
    margin-left: -20px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  .input-pet-details-2 {
    width: 170px;
  }

  .input-pet-details-dropdown {
    width: 170px;
  }
}

/* .bg-image{
    background-image: url('../../assets/pet_banners/thankyoukey.png');
    background-size: 100% 750px !important;
    background-repeat: no-repeat;
    height: 90vh;
} */

.allowlocationtext{
    font-size: 22px;
    margin-top: 480px !important;
    animation: blinker 1s linear infinite;
    color: red;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }


@media only screen and (max-width:1130px){
    .bg-image{
        min-height: 700px !important;
    }
}

@media only screen and (max-width:850px){
    .bg-image{
        background-size: 100% 600px !important;
        min-height: 600px;
    }

    .keyfoundbutton{
        font-size: 16px !important;
        margin-top: 20px !important;
    }

    .allowlocationtext{
        margin-top: 10px !important;
    }
}

@media only screen and (max-width:650px){
    .bg-image{
        background-size: 100% 400px !important;
        height: 400px !important;
    }

    .allowlocationtext{
        margin-top: -120px !important;
        font-size: 20px;
    } 

    .keyfoundbutton{
        font-size: 16px !important;
        height: 35px !important;
        width: 180px !important;
        padding: 1px !important;
    }

    .buttonforbutton{
        margin-top: -200px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -120px !important;
    }
}

@media only screen and (max-width:480px){
    .bg-image{
        background-size: 100% 300px !important;
        height: 300px !important;
    }

    .allowlocationtext{
        margin-top: -140px !important;
        font-size: 18px;
    } 

    .keyfoundbutton{
        font-size: 12px !important;
        height: 30px !important;
        width: 200px !important;
        padding: 0px !important;
        word-wrap: break-word !important;
        white-space: normal !important;
    }

    .buttonforbutton{
        margin-top: -280px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -260px !important;
    }

}

@media only screen and (max-width:350px){
    .bg-image{
        background-size: 100% 200px !important;
        height: 200px !important;
    }

    .allowlocationtext{
        margin-top: -240px !important;
        font-size: 16px;
    } 

    .keyfoundbutton{
        font-size: 12px !important;
        height: 25px !important;
        width: 150px !important;
        padding: -10px !important;
    }

    .buttonforbutton{
        margin-top: -350px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -330px !important;
    }

}
.pet-poster-background{
    width: 60%;
    height: 100%;
    margin: auto;
    border: 1px solid rgb(202,167,82);
    text-align: center;
}

.header-font{
    font-family: 'AbsolutDB';
    color: rgb(202,167,82);
}

.header-text{
    font-size: 80px;
    float: center;
    margin: auto;
    margin-bottom: 30px;
}

.mat-card{
    width: 60%;
    margin: auto;
    margin-top: 30px;
    border: 1px solid  #cca151;
}

.mat-card-header{
    margin-left: 18%;
}

.lost-info-header{
    font-size: 35px;
    line-break: normal;
}

.lost-info-text{
    color:  #cca151;
    font-size: 27px !important;
}

.lost-info-text-address{
    color:  #cca151;
    font-size: 21px;
    margin-top: -10px !important;
}

.answers-to-name{
    width: 100%;
    background-color: rgb(102, 89, 32);
    color: white;
    margin: auto;
}

.answers-to-name-header{
    font-family: 'KibbyBoldFont';
    font-size: 25px;
    transform: scale(1.6,1.4);
}

.answers-to-name-text-1{
    font-family: 'AbsolutDB';
    font-size: 22px !important;
    transform: scale(1.3,1.1);
    font-weight: 400 !important;
    word-wrap: break-word !important;
}

.answers-to-name-text{
    font-family: 'KibbyBoldFont';
    font-size: 25px;
    transform: scale(2.5,2);
    font-weight: 700;
}

.bottom-help-text{
    font-size: 17px;
    transform: scale(1,1);
}

.bottom-text{
    font-family: 'BellCent NamNum BT';
    color: rgb(202,167,82);
    font-size: 27px;
    transform: scale(1.2,1.6);
}

.bottom-text-1{
    font-family: 'BellCent NamNum BT';
    color: rgb(202,167,82);
    font-size: 27px;
    transform: scale(1.6,1.6);
}

.reward-header{
    font-size: 25px;
    transform: scale(1.5,1.8);
}

.yellow-color{
    color: rgb(202,167,82);
}

.button-styling{
    margin:10px;
    font-size: 16px;
    border: none !important;
    outline: none !important;
}

@media only screen and (max-width:1120px){
    .answers-to-name-header{
        font-size: 18px;
    }
    .lost-info-header-res{
        font-size: 28px;
    }
}
@media only screen and (max-width:922px){
    .answers-to-name-header{
        font-size: 15px;
    }
    .lost-info-header-res{
        font-size: 22px;
        line-break: normal;
    }
    .postar-res{
      padding: 5px 10px;
      font-size: 10px;
    }
    .button-styling{
        margin:10px;
        font-size: 18px;
        padding: 5px;
    }
}
@media only screen and (max-width:768px){
    .lost-info-header-res{
        font-size: 20px;
        line-break: normal;
    }
    .button-styling{
        width: 100% !important;
    }
    .marginleftmove{
        margin-left: -20px !important;
        text-align: center !important;
    }
    .marginleftmove1{
        margin-left: 10px !important;
    }
}
@media only screen and (max-width:690px){
    .header-text{
    line-height: 4rem;
    font-size:40px;
    }
    .answers-to-name-header{
        font-size: 8px;
    }
    .button-styling{
        margin:10px;
        font-size: 18px;
        padding: 5px;
    }
}
@media only screen and (max-width:400px){
    .mat-card{
        width: 80%;
    }
    .header-text{
        line-height: 4.5rem;
    }
   .lost-info-header{
       font-size: 20px;
   }

   .answers-to-name{
       padding: 40px !important;
   }
   
   .answers-to-name-header{
       font-size:14px !important;
   }
   .answers-to-name-text{
       font-size: 15px;
   }
   .bottom-text-1 {
       font-size:18px;
   }
   .reward-header{
       padding: 10px 0px 0px 0px;
   }
   .bottom-text{
       padding: 0px 0px 5px 0px;
       font-size: 20px !important;
   }
   .reward-header{
       font-size: 20px !important;
   }
   .answers-to-name-text-1{
       font-size: 18px !important;
   }
   .marginleftmove1{
    margin-left: -5px !important;
}
}
@media only screen and (max-width:298px){
    .lost-info-header-respon{
        font-size: 18px;
    }
    .button-styling{
        margin:12px;
        font-size: 15px;
        padding: 10px;
    }
}

.lock_card_history{
    margin-bottom: 2rem;
    max-width: 75%;
    margin-left: 20%;
}
.lock_card_img{
    width: 120px !important;
    margin: 2rem;
}
.lock_card_date{
    font-size: medium;
    font-weight: bold;
}
@media only screen and (max-width: 767px) {
    .lock_card_history{
    margin-bottom: 2rem;
    margin: 1rem;
    max-width: 100%;
}
.lock_card_img{
    width: 70px !important;
}
}
