* {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  line-height: 20px;
  font-family: "prompt", sans-serif;
}

@media only screen and (max-width: 600px) {
  /* select{
      background-color: white !important;
  } */
  input {
    background-color: white !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
.PageWrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 135vh; */
}

.AppWrapper {
  flex-grow: 1;
}

.FooterWrapper {
  flex-shrink: 0;
  z-index: 1;
}


