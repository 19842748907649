/* @import url("https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Federant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gorditas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap");
@import url("https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB");
@import url("https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT");
@import url("https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont"); */
@import url("https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap");

.bag-poster-toggle-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.bagposter-container {
  background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a 3D effect */
  /* padding: 20px; */
  width: 100%;
  max-width: 1200px;
  /* height: 100vh; */
  margin: auto;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
}

.key-lost-detail {
  margin-top: 1em;
  text-align: center;
  border: 0.5px solid gray;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  transition: all 0.5s;
  bottom: -17.5rem;
  width: 100%;
  padding: 5%;
  padding-bottom: 1rem;
}

.keyposter-lost-details-span {
  width: 100%;
  display: flex;
  gap: 5px;
}

.keyposter-lost-details {
  padding: 1rem;
  border: 0.5px solid gray;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.keyposter-lost-details label,
.keyposter-lost-details p {
  margin-bottom: 0px !important;
}
.poster-container {
  border: 2px solid #000;
  padding: 20px;
  border-radius: 8px;
}
/* .poster-msg-wrapper-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
} */

.poster-msg-wrapper {
  margin-bottom: 16px;
  text-align: center;
}

.poster-msg {
  font-weight: bold;
  margin-bottom: 8px;
}

.poster-owner-details {
  text-align: center;
}

.poster-owner-name {
  font-weight: bold;
  margin-bottom: 8px;
}

.poster-owner-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: white;
}
.custom-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/* .poster-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
} */

.poster-reward-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.poster-reward-currency {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.poster-reward-currency svg {
  margin-right: 4px;
}

.keyposter-lost-details p {
  font-weight: bold;
  font-size: 20px;
}

.genie-nav {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 1000;
  background-color: white;
}

.poster-utility {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 1em;
  padding: 1em;
  z-index: 999;
}

.poster-utility-btns {
  /* width: 100%; */
  border: none;
  border-radius: 5px;
  padding: 1em;
  background-color: whitesmoke;
  text-align: center;
  cursor: pointer;
}
.poster-utility-btns:hover {
  text-decoration: none;
}
.header-font-key {
  font-family: "Prompt", sans-serif !important;
  /* padding: 2rem; */
  color: black;
}

.bottom-inner-div {
  display: flex;
  padding: 2rem;
  justify-content: space-around;
}
.reportfound-message {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.reportfound-message-header {
  letter-spacing: 0.125em;
  font-size: 144%;
  font-family: "Acme", sans-serif;
  margin: 0px !important;
}

.reportfound-message-content {
  letter-spacing: 0.25em;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: 0px !important;
}

.reportfound-message-content-icon {
  font-size: 1.875rem;
}

.reportfound-message-content-name {
  letter-spacing: 1px;
  font-size: 1.625rem;
  font-family: "Prompt", sans-serif;
}

.reportfound-message-ownerid {
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin: 0px !important;
}

#aup,
#adown {
  display: none;
}
a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.pabr {
  border: 0.5px solid gray;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}
.footer-font img {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}

.footer-font {
  font-family: "Prompt", sans-serif;
  margin-bottom: 0px !important;
}
.vector {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}
.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}
.img-container {
  text-align: center;
}
.mat-card-image2 {
  border-radius: 100px;
  height: 180px;
  width: 190px;
  margin: auto;
  background-color: white;
}

.header-text-key {
  font-size: 50px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
  line-height: 70px;
}

.key-mat-card {
  width: 80%;
  margin: auto;
  margin-top: 90px;
  /* border: 1px solid #cca151; */
  box-shadow: none !important;
}

.mat-card-header {
  margin-left: 18%;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
  line-height: normal;
  padding-top: 10px;
  padding-right: 8px;
}

.lost-info-text-key {
  font-size: 21px;
  border: 0.5px solid gray;
  padding: 1.2vw;
  width: 23vw;
  border-radius: 10px;
  font-family: "Prompt", sans-serif;
}

.lost-info-text-address-key {
  font-size: 21px;
  margin: 2rem;
  padding: 1.5rem;
  border: 0.5px solid gray;
  border-radius: 10px;
}
.answers-to-name {
  width: 100%;
  background-color: rgb(102, 89, 32);
  color: white;
  margin: auto;
}

.answers-to-name-header {
  font-family: "Prompt", sans-serif;
  font-size: 25px;
  transform: scale(1.6, 1.4);
}

.answers-to-name-text-1 {
  font-family: "Prompt", sans-serif;
  font-size: 22px !important;
  transform: scale(1.3, 1.1);
  font-weight: 400 !important;
  word-wrap: break-word !important;
}

.answers-to-name-text {
  font-family: "Prompt", sans-serif;
  font-size: 25px;
  transform: scale(2.5, 2);
  font-weight: 700;
}

.bottom-help-text {
  font-size: 17px;
  transform: scale(1, 1);
}

/* .bottom-text {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  transform: scale(1.2, 1.6);
}

.bottom-text-1 {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  transform: scale(1.6, 1.6);
} */

.reward-header {
  font-size: 25px;
  transform: scale(1.5, 1.8);
}

.yellow-color {
  color: rgb(202, 167, 82);
}

.button-styling {
  margin: 10px;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}
.poster-msg-wrapper-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1em;
}

.poster-msg-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.poster-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  gap: 0.5em;
}

.poster-msg > p {
  margin: 0px !important;
  font-size: 1.6rem;
  margin: 20px 0px;
  color: #f6be07;
  font-family: "Prompt", sans-serif;
}

.poster-owner-details {
  padding: 0.0625em 1.875em;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-owner-name {
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

.poster-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 6px;
}

.poster-info-wrapper p {
  margin-bottom: 0px !important;
  color: #f6be07;
  font-size: 20px;
}

.poster-owner-phone {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

.poster-reward {
  padding: 0.0625em 1.875em;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poster-reward-title {
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

.poster-reward-currency {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.5625rem;
  margin: 0.625em 0px;
}

@media only screen and (max-width: 1120px) {
  .answers-to-name-header {
    font-size: 18px;
  }
  .lost-info-header-res {
    font-size: 28px;
  }
}
@media only screen and (max-width: 922px) {
  .answers-to-name-header {
    font-size: 15px;
  }
  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }
  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }
  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }
  .lost-info-text-key {
    width: 20vw;
    /* padding: 1rem; */
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 768px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }
  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
  }
  .button-styling {
    width: 100% !important;
  }
  .marginleftmove {
    margin-left: -20px !important;
    text-align: center !important;
  }
  .marginleftmove1 {
    margin-left: 10px !important;
  }
  .lost-data-header {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 690px) {
  .header-text-key {
    line-height: 4rem;
    font-size: 40px;
    padding: -1rem 8rem 0rem 8rem;
    margin-top: 5rem !important;
  }
  #aup {
    display: block;
  }
  .answers-to-name-header {
    font-size: 8px;
  }
  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }
  .key-lost-detail p {
    padding-bottom: 1rem;
    font-size: 16px !important;
    font-weight: 100 !important;
  }

  .bottom-inner-div {
    flex-direction: column;
    justify-content: center;
  }

  .main-img2 {
    text-align: center;
    padding-top: 6vh;
    position: absolute;
    top: -120px;
    left: 48%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
  .key-mat-card {
    border: none;
    width: 100%;
    margin: 0;
    margin-bottom: 13rem !important;
  }

  /* .colorw {
    background-color: white;
  } */
  .lost-valuable {
    /* margin-top: 30px !important; */
    margin-bottom: 0px !important;
  }
  .background {
    background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
    /* background-image: url("../../assets/seamless-pattern-bags-doodle-style-hand-drawn-woman-accessories-background-vector-black-white-design.jpg"); */
  }
  .img-container {
    text-align: left;
    padding: 6rem;
    padding-top: 0;
    /* background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c); */
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .mat-card-image2 {
    height: 160px !important;
    width: 160px !important;
    border: 5px solid white;
  }
  .bottom-inner-div {
    padding: 0;
  }
  .footer-font img {
    max-width: 30px;
    max-height: 30px;
    margin-left: 2px;
    margin-right: 8px;
  }

  .vector {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .lost-info-text-key {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 43vw;
    /* height: 10vh; */
  }
  .header-font-key {
    padding-bottom: 6rem;
  }
  .footer-font {
    text-align: left;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    gap: 2px;
  }
}
.poster-msg-title {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Prompt", sans-serif;
  color: #cca151;
}

@media only screen and (max-width: 500px) {
  .lost-data-header {
    font-size: 35px !important;
  }
  .main-img2 {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 45%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .key-lost-detail {
    position: fixed;
    bottom: -17.5rem;
    padding: 5%;
    padding-bottom: 2vh;
  }
}

@media only screen and (max-width: 450px) {
  .poster-utility {
    flex-direction: column;
    position: absolute;
    background-color: white;
    transition: all ease-in-out 500ms;
  }

  .poster-utility-hide {
    display: none;
    transition: all ease-in-out 500ms;
  }

  .main-img2 {
    text-align: center;
    position: absolute;
    top: -175px;
    left: 42%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .key-lost-detail {
    position: fixed;
    bottom: -14.5rem;
    padding: 5%;
    padding-bottom: 2vh;
    border: 0.5px solid gray;
    border-radius: 20px 20px 0 0;
  }
  .lost-info-text-key {
    width: 48vw;
  }
}

@media only screen and (max-width: 400px) {
  .key-mat-card {
    width: 100%;
  }
  .header-text-key {
    line-height: 2.5rem;
  }
  .lost-info-header {
    font-size: 20px;
  }

  .answers-to-name {
    padding: 40px !important;
  }

  .answers-to-name-header {
    font-size: 14px !important;
  }
  .answers-to-name-text {
    font-size: 15px;
  }
  .bottom-text-1 {
    font-size: 18px;
  }
  .reward-header {
    padding: 10px 0px 0px 0px;
  }
  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }
  .reward-header {
    font-size: 20px !important;
  }
  .answers-to-name-text-1 {
    font-size: 18px !important;
  }
  .marginleftmove1 {
    margin-left: -5px !important;
  }

  .key-lost-detail {
    padding: 5%;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }
  .button-styling {
    margin: 12px;
    font-size: 15px;
    padding: 10px;
  }
  .key-mat-card {
    width: 100%;
  }
  .lost-data-header {
    font-size: 55px;
    text-align: center;
    margin-top: 30px;
  }

  .key-lost-detail {
    bottom: -17.5rem;
  }
}

@media (max-width: 768px) {
  .poster-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .poster-container {
    border-width: 1px;
    padding: 0rem 0rem;
    border-radius: 4px;
  }
}
@media screen and (min-width: 37.5em) {
  .bag-poster-toggle-btn {
    display: none;
  }
}

@media screen and (min-width: 48em) {
  .reportfound-message-header {
    letter-spacing: 1px;
  }
  .bg-gray {
    background-color: transparent !important;
  }

  .keyposter-lost-details-span {
    gap: 1em;
  }
}

@media screen and (min-width: 50em) {
  .keyposter-lost-details-span {
    width: 36em;
  }
}

@media screen and (width: 51.25em) {
  .reportfound-message-header {
    letter-spacing: 1px;
  }
}

@media screen and (width: 22.5em) {
  .header-font-key {
    padding-bottom: 6rem;
  }
}

@media (max-width: 767px) {
  .col-md-2 {
    width: 100%;
    padding: 2rem 2rem 1rem 3rem;
  }
}
@media (min-width: 350px) and (max-width: 450px) {
  .container-for-rows {
    gap: 10px; /* Reduce the gap between rows on smaller screens */
  }
  .custom-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .custom-container img {
    max-width: 100%; /* Make the image responsive and fit the container width */
    height: auto; /* This will maintain the aspect ratio */
  }
}
