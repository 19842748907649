/* .bg-image{
    background-image: url('../../assets/pet_banners/thankyoukey.png');
    background-size: 100% 750px !important;
    background-repeat: no-repeat;
    height: 90vh;
} */

.allowlocationtext{
    font-size: 22px;
    margin-top: 480px !important;
    animation: blinker 1s linear infinite;
    color: red;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }


@media only screen and (max-width:1130px){
    .bg-image{
        min-height: 700px !important;
    }
}

@media only screen and (max-width:850px){
    .bg-image{
        background-size: 100% 600px !important;
        min-height: 600px;
    }

    .keyfoundbutton{
        font-size: 16px !important;
        margin-top: 20px !important;
    }

    .allowlocationtext{
        margin-top: 10px !important;
    }
}

@media only screen and (max-width:650px){
    .bg-image{
        background-size: 100% 400px !important;
        height: 400px !important;
    }

    .allowlocationtext{
        margin-top: -120px !important;
        font-size: 20px;
    } 

    .keyfoundbutton{
        font-size: 16px !important;
        height: 35px !important;
        width: 180px !important;
        padding: 1px !important;
    }

    .buttonforbutton{
        margin-top: -200px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -120px !important;
    }
}

@media only screen and (max-width:480px){
    .bg-image{
        background-size: 100% 300px !important;
        height: 300px !important;
    }

    .allowlocationtext{
        margin-top: -140px !important;
        font-size: 18px;
    } 

    .keyfoundbutton{
        font-size: 12px !important;
        height: 30px !important;
        width: 200px !important;
        padding: 0px !important;
        word-wrap: break-word !important;
        white-space: normal !important;
    }

    .buttonforbutton{
        margin-top: -280px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -260px !important;
    }

}

@media only screen and (max-width:350px){
    .bg-image{
        background-size: 100% 200px !important;
        height: 200px !important;
    }

    .allowlocationtext{
        margin-top: -240px !important;
        font-size: 16px;
    } 

    .keyfoundbutton{
        font-size: 12px !important;
        height: 25px !important;
        width: 150px !important;
        padding: -10px !important;
    }

    .buttonforbutton{
        margin-top: -350px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -330px !important;
    }

}