/* html,
body {
  margin: 0;
  padding: 0;
} */
.dashboard-activateTag-popup {
  font-family: "prompt", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.dashboard-input__field__dialogs {
  padding: 0.375em 0.75em;
  border: 1px solid black;
  border-radius: 15px;
}
.dashboard-verifyOtp-button {
  font-family: "prompt", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #ffc107 !important;
  letter-spacing: 1px !important;
  padding: 8px 16px !important;
  border: none !important;
  outline: none !important;
  border-radius: 15px !important;
  color: white !important;
  box-shadow: 0px 2px 5px 0px #888888 !important;
}

.dashboard-verifyOtp-button:hover {
  background-color: #ebb105 !important;
}
.left-image {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
  margin-top: 0rem;
  width: 50%;
}
.bagActivation-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.bag-container {
  background-color: #c7c7c781;
  width: 100%;
  max-width: 100%;
  height: 135vh; /* Default height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh 0 0 8vw;
}


.background-image {
  background: whitesmoke;
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
}

.bagActivation-left-wrapper {
  position: relative;
  background-color: white;
}
.bag-activation-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5em;
}
.bagActivation-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.bagActivation-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.bagActivation-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.bagActivation-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.bagActivation-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.bagActivation-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bagActivation-sidebar-menu-ul-hide {
  display: none;
}

.bagActivation-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.bag-activation-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  padding: 1em;
}

.bagactivation-label-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.bagactivation-label-petdata {
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.bag-activation-container > p {
  font-family: "prompt", sans-serif;
  text-align: center;
}

.bagactivation-input-tag {
  margin-top: 0 !important;
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.bagactivation-drop-down {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
}

.bagactivation-btn-grp {
  display: flex;
  gap: 2em;
  padding: 1rem 5rem 1rem 4rem;
}

.bag-image-upload {
  /* width: 120px;
  height: 120px;
  margin: 0rem 9rem 20rem -9rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  gap: 0.9rem;
  /* display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginBottom: "5rem",
                      gap: "0.9em", */
}

#choose-profile-imageActi {
  display: none;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}
.key-activation input {
  padding: 0px !important;
}

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.bag-image {
  height: 256px;
  width: 256px;
  border-radius: 20%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.leftside-navigation {
  list-style: none;
  margin: auto;
  margin-left: -30px;
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline {
  border-bottom: 5px solid #fcbc1b;
}

textarea {
  min-height: 20px !important;
  border: 2px solid #e4e4e4;
  width: 200px !important;
  font-size: 14px;
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #e4e4e4 !important;
}

.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

p strong {
  font-size: 30px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
}

/* input[readonly] {
  background-color: white;
} */

.submit-login {
  width: 100%;
  max-height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

.multiple-images {
  transition: transform 0.3s;
}

.multiple-images:hover {
  transform: scale(2.5);
}

@media only screen and (max-width: 990px) {
  .body-login {
    width: 100%;
    margin-top: 50px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
    width: 100%;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .row-underline {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media (max-width: 1920px) {
  .bag-container {
    padding: 10vh 0 0 0vw;
  }

  /* Adjust height for "OtherKeys" */
  .bag-container.other-keys {
    height: 100vh; /* Reduced height for "OtherKeys" */
  }
}
@media (max-width: 1536px) {
  .bag-container {
    padding: 0vh 0 0 4vw;
    height: 155vh; /* Default increased height */
  }

  /* Adjust height for "OtherKeys" */
  .bag-container.other-keys {
    height: 120vh; /* Adjust this height for smaller screens */
  }
}
@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  .bag-container {
    margin-top: auto;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }

  .bag-activation-form {
    flex-direction: column;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
  .bag-container {
    margin-top: auto;
    height: auto;
  }
  .bag-activation-form {
    flex-direction: column;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  .input-pet-details-2 {
    width: 200px;
  }

  .input-pet-details-dropdown {
    width: 200px;
  }
  .submit-login {
    width: 200px !important;
  }

  .bag-activation-form {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
  .bag-container {
    margin-top: auto;
  }
}
@media only screen and (max-width: 375px) {
  .bag-container {
    margin-top: 9rem;
  }
  .bag-activation-form {
    flex-direction: column;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}
@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    width: 160px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  .input-pet-details-2 {
    width: 170px;
  }

  .input-pet-details-dropdown {
    width: 170px;
  }
}

/* @media screen and (min-width: 48em) {
  .bag-activation-container {
    padding: 0 7em;
  }
} */

@media screen and (min-width: 62.5em) {
  .bagActivation-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .bagActivation-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 75em) {
  .bag-activation-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .bag-activation-form {
    padding: 0 10%;
  } */
}

/* Media query for small mobile screens */
@media (max-width: 768px) {
  .left-image {
    width: 100%; /* Change width to occupy the full width of the screen */
    /* Adjust other styles as needed for small screens */
  }
}

@media screen and (min-width: 78.125em) {
  .bagActivation-sidebar-menu-link {
    font-family: "prompt", sans-serif;
    font-size: 1rem;
  }

  .bagActivation-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }

  /* .bag-activation-form {
    padding: 0 20%;
  } */

  /* .bag-activation-container {
    margin: 2% 0;
  } */
}

@media only screen and (max-width: 600px) {
  .bag-container{
    margin-top: 0;
  }
  /* .editPetDetails-main-wrapper{
    top: 5.3rem;
  } */
}
