.lock_card_history{
    margin-bottom: 2rem;
    max-width: 75%;
    margin-left: 20%;
}
.lock_card_img{
    width: 120px !important;
    margin: 2rem;
}
.lock_card_date{
    font-size: medium;
    font-weight: bold;
}
@media only screen and (max-width: 767px) {
    .lock_card_history{
    margin-bottom: 2rem;
    margin: 1rem;
    max-width: 100%;
}
.lock_card_img{
    width: 70px !important;
}
}